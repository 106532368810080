/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  categoryListStore,
  categorySettingsStore,
  PaymentCategoryItem,
} from "@cashbook/data-store/storage"
import {
  Alert,
  Button,
  FormField,
  formikOnSubmitWithErrorHandling,
  Modal,
  ModalBody,
  ModalFooter,
  useOverlayTriggerState,
  Text,
  Stack,
  Box,
  Inline,
  LabelIcon,
  Circle,
  CBButton,
  PlusIcon,
  CancelIcon,
  SearchIcon,
  SearchSelect,
  TSelectableBaseOption,
  InformationWarningIcon,
} from "@cashbook/web-components"
import { Form, Formik } from "formik"
import {
  useCallback,
  useMemo,
  useReducer,
  useState,
  useSyncExternalStore,
} from "react"
import * as Validator from "yup"
import toast from "react-hot-toast"
import { Optional } from "utility-types"
import {
  useAddPaymentCategory,
  useAssignCategory,
  useDeleteCategory,
  useUpdatePaymentCategory,
} from "@cashbook/data-store/payments"
import { TActionType } from "../Transactions/TransferActions"
import { Radio } from "../common"
import { pluralize } from "@cashbook/util-general"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"

function AddFieldFormInDialog({
  fieldLabel,
  fieldNamePlaceholder,
  children,
  initialName,
  onSubmit,
  onCancel,
  isLoading,
}: {
  fieldLabel: string
  fieldNamePlaceholder: string
  initialName?: string
  children: (props: { add: (initialName?: string) => void }) => React.ReactNode
  onSubmit: (data: { name: string }) => PaymentCategoryItem
  onCancel?: () => void
  actionOrigin?:
    | "entryFieldSettings"
    | "chooseParty"
    | "chooseCategory"
    | "choosePaymentMode"
  isLoading?: boolean
}) {
  type TState = { initialName?: string; isOpen: boolean }
  const [state, setState] = useReducer(
    (state: TState, action: Optional<TState>) => ({ ...state, ...action }),
    { initialName: "", isOpen: false }
  )

  const close = useCallback(() => {
    setState({ isOpen: false, initialName: "" })
  }, [])

  const cancel = useCallback(() => {
    close()
    onCancel?.()
  }, [close, onCancel])

  return (
    <>
      {children({
        add: (initialName) => setState({ initialName, isOpen: true }),
      })}
      <Modal
        title={`Add New ${fieldLabel}`}
        isOpen={state.isOpen}
        onClose={cancel}
        size="sm"
      >
        <Formik
          initialValues={{ name: state.initialName || initialName || "" }}
          validationSchema={Validator.object().shape({
            name: Validator.string()
              .required(`Please provide a name for the category`)
              .max(191, "Name should be bellow 191 characters"),
          })}
          validateOnBlur={false}
          onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
            await onSubmit({
              ...values,
              name: values.name.trim(),
            })
            close()
            toast.success(`"${values.name}" ${fieldLabel} added.`)
          })}
        >
          {({ status, isSubmitting }) => (
            <Form noValidate>
              <ModalBody>
                <FormField
                  type="text"
                  name="name"
                  label={`${fieldLabel} Name`}
                  placeholder={fieldNamePlaceholder}
                  required
                  autoFocus
                  hideAsterisk
                />
                {status ? <Alert status="error">{status}</Alert> : null}
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  disabled={isSubmitting || isLoading}
                  size="lg"
                >
                  {isSubmitting || isLoading ? "Saving..." : "Save"}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export function AddPaymentCategoryInDialog({
  businessId,
  onSuccess,
  ...props
}: Omit<
  React.ComponentProps<typeof AddFieldFormInDialog>,
  "onSubmit" | "fieldLabel" | "fieldNamePlaceholder"
> & {
  businessId: string
  onSuccess?: (category: PaymentCategoryItem) => void
}) {
  const { addNewCategoryNetworkCall: addNewCategory, isLoading } =
    useAddPaymentCategory(businessId)
  return (
    <AddFieldFormInDialog
      {...props}
      fieldLabel="Category"
      fieldNamePlaceholder=""
      onSubmit={(values) => {
        let newCategory: PaymentCategoryItem = {
          id: null,
          name: values.name,
          mcc: [],
          tag_count: 0,
          default_id: null,
        }
        addNewCategory(values.name)
          .then((res: any) => {
            newCategory = {
              ...res,
            }
            onSuccess?.(newCategory)
          })
          .catch(() => null)
        return newCategory
      }}
      isLoading={isLoading}
    />
  )
}

function EditPaymentCategory({
  defaultValue,
  onUpdateTransactionNote,
  isLoading,
}: {
  defaultValue: { name: string; id: string }
  onUpdateTransactionNote: (category: { name: string; id: string }) => void
  isLoading: boolean
}) {
  return (
    <Formik
      initialValues={{ category: defaultValue.name }}
      validationSchema={Validator.object().shape({
        category: Validator.string()
          .required(`Please enter a valid note`)
          .max(191, "Name should be bellow 191 characters"),
      })}
      validateOnBlur={false}
      onSubmit={formikOnSubmitWithErrorHandling((values) => {
        onUpdateTransactionNote({ id: defaultValue.id, name: values.category })
      })}
    >
      {({ errors }) => (
        <Form noValidate>
          <ModalBody>
            <FormField
              type="text"
              defaultValue={defaultValue.name || ""}
              name="category"
              label="Category"
              placeholder=""
              required
              autoFocus
              hideAsterisk
            />
          </ModalBody>
          <ModalFooter>
            <CBButton
              type="submit"
              disabled={Boolean(errors?.category) || isLoading}
              size="lg"
              loading={isLoading}
            >
              {"Save"}
            </CBButton>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

export function EditPaymentCategoryInDialog({
  children,
  businessId,
  onSuccess,
  ...props
}: Omit<
  React.ComponentProps<typeof EditPaymentCategory>,
  "onUpdateTransactionNote" | "isLoading"
> & {
  children: (props: { open: () => void }) => React.ReactNode
  businessId: string
  onSuccess?: (category: PaymentCategoryItem) => void
}) {
  const state = useOverlayTriggerState({})
  const { updateCategoryNetworkCall, isLoading } =
    useUpdatePaymentCategory(businessId)
  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        isOpen={state.isOpen}
        size="sm"
        onClose={state.close}
        title={`${props.defaultValue ? "Update" : "Add"} Category`}
      >
        <EditPaymentCategory
          {...props}
          onUpdateTransactionNote={(category) => {
            updateCategoryNetworkCall(category.name, category.id)
              .then((data: any) => {
                onSuccess?.(data)
              })
              .catch(() => null)
              .finally(() => {
                state.close()
              })
          }}
          isLoading={isLoading}
        />
      </Modal>
    </>
  )
}

export default function ChooseCategoryInModal({
  children,
  ...props
}: Omit<
  React.ComponentProps<typeof ChangeFieldsAction>,
  "action" | "onClose" | "label"
> & {
  children: (props: {
    handleAction: (action: TActionType) => void
  }) => React.ReactNode
}) {
  const [action, setAction] = useState<TActionType | null>(null)
  const onBack = useCallback(() => {
    setAction(null)
  }, [])

  return (
    <>
      {children({
        handleAction: (action: TActionType) => {
          setAction(action)
        },
      })}
      <Modal
        isOpen={Boolean(action)}
        onClose={onBack}
        placement="right"
        isDismissable
        title={`Select Category`}
      >
        {action ? (
          <ChangeFieldsAction onClose={() => setAction(null)} {...props} />
        ) : null}
      </Modal>
    </>
  )
}

function ChangeFieldsAction({
  npciTranscationIds,
  onClose,
  onSuccess,
  businessId,
  selectedField: preSelectedField,
  source,
}: {
  npciTranscationIds: string[]
  onClose: () => void
  onSuccess: (selectedField: PaymentCategoryItem | undefined) => void
  businessId: string
  selectedField?: PaymentCategoryItem
  source: "transactionDetails" | "businessTransactions" | "walletTransactions"
}) {
  const navigate = useNavigate()
  const categorySettingsMappedWithBusinessId = useSyncExternalStore(
    categorySettingsStore.subscribe,
    categorySettingsStore.getCategorySettings
  )
  const categorySettings = categorySettingsMappedWithBusinessId[businessId]
  const categoriesListMappedWithBusinessId = useSyncExternalStore(
    categoryListStore.subscribe,
    categoryListStore.getCategorySettings
  )
  const categoriesList = categoriesListMappedWithBusinessId[businessId]
  const isCategoryRequired = Boolean(categorySettings.required)

  const fields = useMemo(() => {
    return categoriesList
  }, [categoriesList])

  const [search, setValue] = useState("")

  const filteredFields = useMemo(() => {
    return search?.length
      ? fields?.filter(
          (field) =>
            Boolean(field.enabled) &&
            field.name.toLowerCase().includes(search.toLowerCase())
        )
      : fields?.filter((field) => Boolean(field.enabled))
  }, [fields, search])

  const [selectedCategory, setSelectedCategory] = useState<
    PaymentCategoryItem | undefined
  >(preSelectedField)

  return (
    <>
      <ModalBody>
        {fields?.length ? (
          <Stack gap="4">
            {fields.length > 5 ? (
              <Box flex="1">
                <Inline
                  position="relative"
                  rounded="md"
                  height="10"
                  paddingRight="2"
                  alignItems="stretch"
                  gap="2"
                  maxWidth="lg"
                  width="full"
                  borderWidth="1"
                  borderColor="borderOutline"
                  className="bg-opacity-20 focus-within:border-blue-900 focus-within:ring-1 ring-blue-900"
                >
                  <input
                    type="search"
                    name="search"
                    placeholder={`Search Category`}
                    value={search}
                    onChange={(e) => setValue(e.target.value)}
                    className="bg-transparent outline-none flex-1 pl-4 placeholder:gray-500"
                  />
                  <Inline
                    as="button"
                    type="button"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => {
                      if (search) setValue("")
                    }}
                  >
                    {search ? (
                      <CancelIcon color="gray900" />
                    ) : (
                      <SearchIcon color="gray500" />
                    )}
                  </Inline>
                </Inline>
              </Box>
            ) : null}
            {!isCategoryRequired ? (
              <Inline
                as="li"
                borderWidth="1"
                width="full"
                paddingX="4"
                paddingY="3"
                gap="4"
                onClick={() => {
                  setSelectedCategory(undefined)
                }}
                cursor="pointer"
                borderColor={
                  !selectedCategory ? "surfacePrimaryLowest" : "borderOutline"
                }
                rounded="md"
                className={classNames({
                  "hover:bg-[#F5F5F5]": selectedCategory,
                  "cursor-not-allowed": !selectedCategory,
                })}
                backgroundColor={
                  !selectedCategory ? "surfacePrimaryLowest" : "transparent"
                }
                alignItems="center"
              >
                <Radio isSelected={!selectedCategory} />
                <Text fontSize="b3">No Category</Text>
              </Inline>
            ) : null}
            {filteredFields?.length ? (
              <Stack as="ul" gap="2" paddingTop="2">
                {filteredFields.map((field) => {
                  const { default_id, name, id } = field
                  const isSelected =
                    Boolean(
                      selectedCategory?.default_id &&
                        default_id === selectedCategory?.default_id
                    ) ||
                    Boolean(selectedCategory?.id && id === selectedCategory?.id)
                  return (
                    <Inline as="li" key={default_id || id}>
                      <Inline
                        as="label"
                        borderWidth={"1"}
                        width="full"
                        paddingX="4"
                        paddingY="3"
                        gap="4"
                        onClick={() => {
                          setSelectedCategory(field)
                        }}
                        className={classNames({
                          "hover:bg-[#F5F5F5]": !isSelected,
                          "cursor-not-allowed": isSelected,
                        })}
                        cursor="pointer"
                        borderColor={
                          isSelected ? "surfacePrimaryLowest" : "borderOutline"
                        }
                        rounded="md"
                        backgroundColor={
                          isSelected ? "surfacePrimaryLowest" : "transparent"
                        }
                        alignItems="center"
                      >
                        <Radio isSelected={isSelected} />
                        <Stack gap="2">
                          <Text fontSize="b3">{name}</Text>
                        </Stack>
                      </Inline>
                    </Inline>
                  )
                })}
              </Stack>
            ) : (
              <Stack gap="16" paddingTop="2">
                <AddPaymentCategoryInDialog businessId={businessId}>
                  {({ add }) => (
                    <Inline
                      gap="4"
                      rounded="md"
                      paddingX="4"
                      paddingY="2"
                      alignItems="center"
                      height="12"
                      as="button"
                      onClick={() => {
                        if (search) {
                          add(search)
                        } else {
                          navigate(
                            `/businesses/${businessId}/payments/settings`
                          )
                        }
                      }}
                      backgroundColor="surfacePrimaryLowest"
                    >
                      <PlusIcon color="iconPrimary" />
                      <Text fontSize="b3">
                        {search ? `Add ‘${search}’` : "Go to payment settings"}
                      </Text>
                    </Inline>
                  )}
                </AddPaymentCategoryInDialog>
                <Stack
                  gap="6"
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Circle>
                    <LabelIcon />
                  </Circle>
                  <Stack gap="2">
                    <Text fontSize="s3">
                      {search
                        ? `No categories found!`
                        : `All categories are disabled`}
                    </Text>
                    <Text fontSize="b3" color="textMedium">
                      {search
                        ? `Check spelling or try some other search term`
                        : `Go to Payment Settings > Manage Category > Enable the category that you want to select`}
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
        ) : null}
      </ModalBody>
      {fields?.length ? (
        <ModalFooter>
          <ConfirmationModal
            source={source}
            label="Category"
            npciTransactionIds={npciTranscationIds}
            selectedField={selectedCategory}
            businessId={businessId}
            onSuccess={(selectedField, npciTranscationIds) => {
              const numberOfTransactions = npciTranscationIds.length
              onClose()
              onSuccess(selectedField)
              toast.success(
                selectedField?.name
                  ? `'${
                      selectedField?.name
                    }' category updated in ${numberOfTransactions} ${pluralize(
                      "transaction",
                      numberOfTransactions
                    )}`
                  : preSelectedField?.name
                  ? `${
                      preSelectedField?.name
                    } removed from ${numberOfTransactions} ${pluralize(
                      "transaction",
                      numberOfTransactions
                    )}`
                  : `Category removed from ${numberOfTransactions} ${pluralize(
                      "transaction",
                      numberOfTransactions
                    )}`
              )
            }}
          >
            {({ open }) => (
              <CBButton level="primary" size="lg" onClick={open}>
                Update
              </CBButton>
            )}
          </ConfirmationModal>
        </ModalFooter>
      ) : (
        <ModalFooter>
          <CBButton size="lg" onClick={onClose}>
            Close
          </CBButton>
        </ModalFooter>
      )}
    </>
  )
}

function ConfirmationModal({
  label,
  npciTransactionIds,
  selectedField,
  children,
  onSuccess,
  businessId,
  source,
}: {
  label: string
  selectedField?: PaymentCategoryItem
  npciTransactionIds: string[]
  onSuccess?: (
    selectedField: PaymentCategoryItem | undefined,
    npciTransactionIds: string[]
  ) => void
  children: (props: { open: () => void }) => React.ReactNode
  businessId: string
  source: "transactionDetails" | "businessTransactions" | "walletTransactions"
}) {
  const state = useOverlayTriggerState({})
  const transactionCount = npciTransactionIds.length
  const { assignCategoryNetworkCall } = useAssignCategory(businessId, source)

  const pointers = useMemo(() => {
    return [
      `You have selected ${transactionCount} ${pluralize(
        "transaction",
        transactionCount
      )}`,
      !selectedField?.name
        ? `All selected ${pluralize(
            "transaction",
            transactionCount
          )} will not have any ${label?.toLowerCase()} assigned`
        : `Selected ${pluralize(
            "transaction",
            transactionCount
          )} will be added to '${selectedField?.name}' ${label?.toLowerCase()}`,
    ]
  }, [selectedField?.name, transactionCount, label])

  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title={`Change ${label}`}
      >
        <Formik
          initialValues={{
            npciTransactionIds,
            selectedField: selectedField as PaymentCategoryItem | undefined,
          }}
          onSubmit={formikOnSubmitWithErrorHandling(
            async ({ npciTransactionIds, selectedField }) => {
              await assignCategoryNetworkCall(
                npciTransactionIds,
                selectedField?.id || "",
                selectedField?.default_id || ""
              )
              onSuccess?.(selectedField, npciTransactionIds)
            }
          )}
        >
          {({ status, isSubmitting }) => (
            <Form noValidate>
              <ModalBody>
                <Stack as="ul" gap="4">
                  <Text fontSize="b3">Are you sure?</Text>
                  {pointers.map((pointer) => (
                    <Inline as="li" gap="4" alignItems="center" key={pointer}>
                      <Circle size="2" backgroundColor="iconLow" />
                      <Text fontSize="b3">{pointer}</Text>
                    </Inline>
                  ))}
                </Stack>
                {status ? <Alert status="error">{status}</Alert> : null}
              </ModalBody>
              <ModalFooter>
                <CBButton type="submit" size="lg" loading={isSubmitting}>
                  Yes, Change
                </CBButton>
                <CBButton onClick={state.close} size="lg">
                  Cancel
                </CBButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export function DeletePaymentCategoryInDialog({
  children,
  businessId,
  categoryToBeDeleted,
}: {
  children: (props: { open: () => void }) => React.ReactNode
  businessId: string
  categoryToBeDeleted: PaymentCategoryItem
}) {
  const transactionCount = categoryToBeDeleted?.tag_count || 0
  const [selectedCategory, setSelectedCategory] = useState<
    PaymentCategoryItem | undefined
  >(undefined)
  const state = useOverlayTriggerState({})
  const categoriesListMappedWithBusinessId = useSyncExternalStore(
    categoryListStore.subscribe,
    categoryListStore.getCategorySettings
  )
  const selectedCategoryOption = useMemo(() => {
    return {
      id: selectedCategory?.id || selectedCategory?.default_id || "",
      label: selectedCategory?.name || "",
    }
  }, [
    selectedCategory?.default_id,
    selectedCategory?.id,
    selectedCategory?.name,
  ])

  const categoriesList = categoriesListMappedWithBusinessId[businessId]

  const categorOptionsForDropDown = useMemo(() => {
    const categoryListForDropDown: TSelectableBaseOption[] = []
    categoriesList.forEach((categoryItem) => {
      if (
        categoryItem.enabled &&
        ((categoryToBeDeleted?.id &&
          categoryToBeDeleted?.id !== categoryItem?.id) ||
          (categoryToBeDeleted?.default_id &&
            categoryToBeDeleted?.default_id !== categoryItem?.default_id))
      ) {
        categoryListForDropDown.push({
          id: categoryItem.id || categoryItem.default_id || "",
          label: categoryItem.name,
        })
      }
    })
    return categoryListForDropDown
  }, [categoriesList, categoryToBeDeleted?.default_id, categoryToBeDeleted?.id])

  const { isLoading, deleteCategoryNetworkCall } = useDeleteCategory(businessId)

  const onConfirmDelete = () => {
    deleteCategoryNetworkCall(
      categoryToBeDeleted.id || "",
      selectedCategory?.id || "",
      selectedCategory?.id ? "" : selectedCategory?.default_id || ""
    )
      .then(() => {
        toast.success("Category deleted successfully!")
      })
      .catch(() => null)
      .finally(() => {
        setSelectedCategory(undefined)
        state.close()
      })
  }

  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        isOpen={state.isOpen}
        onClose={() => {
          setSelectedCategory(undefined)
          state.close()
        }}
        title="Delete Payment Category"
        size="sm"
        placement="right"
      >
        <ModalBody>
          <Alert status={"error"}>
            <Inline alignItems="center">
              <InformationWarningIcon />
              <Stack marginLeft="4">
                <Box>
                  <Text color="iconHigh">{`Delete '${categoryToBeDeleted.name}'`}</Text>
                </Box>
                <Box marginTop="2">
                  <Text color="iconHigh">
                    Are you sure? This can't be undone
                  </Text>
                </Box>
              </Stack>
            </Inline>
          </Alert>
          {transactionCount > 0 ? (
            <Stack>
              <Text>Reassign Category</Text>
              <Alert status="warning" marginTop="4">
                <Text>{`${transactionCount} ${pluralize(
                  "transaction",
                  transactionCount
                )} ${
                  transactionCount > 1 ? "are" : "is"
                } currently assigned to this category. You can reassign ${
                  transactionCount > 1 ? "them" : "it"
                } to a different category if needed. Please select a new category to proceed.`}</Text>
              </Alert>
              <Box marginTop="4">
                <SearchSelect
                  searchPlaceholder="Search or Select"
                  control="input"
                  value={selectedCategoryOption}
                  options={categorOptionsForDropDown}
                  onChange={(option) => {
                    const selectedCategoryOption = categoriesList.find(
                      (category) =>
                        category.id === option?.id ||
                        category.default_id === option?.id
                    )
                    setSelectedCategory(selectedCategoryOption)
                  }}
                />
              </Box>
            </Stack>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <CBButton
            type="submit"
            size="lg"
            disabled={isLoading}
            loading={isLoading}
            status="danger"
            onClick={onConfirmDelete}
          >
            Delete
          </CBButton>
          <CBButton
            onClick={() => {
              setSelectedCategory(undefined)
              state.close()
            }}
            disabled={isLoading}
            size="lg"
          >
            Cancel
          </CBButton>
        </ModalFooter>
      </Modal>
    </>
  )
}
