import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"
import { useParams } from "react-router-dom"
import {
  AddMoneyIcon,
  Box,
  Button,
  CBButton,
  CancelIcon,
  CheckIcon,
  Circle,
  CopyIcon,
  CopyToClipboard,
  DataLoadingFallback,
  DocumentDownloadIcon,
  ExcelFileIcon,
  Inline,
  Modal,
  ModalBody,
  ModalFooter,
  PDFIcon,
  PageMeta,
  SearchSelect,
  SkeletonRows,
  SkeletonTitle,
  SpinnerIcon,
  Stack,
  TSelectableBaseOption,
  Text,
  Time,
  Tooltip,
  TransactionDate,
  useOverlayTriggerState,
} from "@cashbook/web-components"
import {
  TBusiness,
  TBusinessMember,
  TBusinessUser,
  useBusiness,
} from "@cashbook/data-store/businesses"
import { Amount } from "../support/Intl"
import {
  DeactivatedWallet,
  DownloadBusinessTransactionsReportFilters,
  PartyForFilters,
  PaymentsTransaction,
  PdfColumns,
  transformDate,
  useDeactivatedWallets,
  useDownloadBusinessTransactionsReport,
  useFetchParties,
  useOverallBusinessTransactions,
} from "@cashbook/data-store/payments"
import { TUser, useProfile } from "@cashbook/data-store/users"
import {
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
  useSyncExternalStore,
} from "react"
import {
  Avatar,
  CopyTransactionsToCashbook,
  DateSelectForPayments,
  GeneralErrorHandler,
  TransactionDetailsInModal,
  allowedStatusForCopyTransactions,
  allowedStatusForSetExtraInfoTransactions,
  getHelperIconForStatus,
  getStatusTitle,
  getTextColorForStatus,
} from "../Payments"
import { formatDate } from "@cashbook/util-dates"
import { toast } from "react-hot-toast"
import config from "../config"
import { pluralize } from "@cashbook/util-general"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"
import { Checkbox } from "../common"
import classNames from "classnames"
import ObopayLogo from "../Payments/ObopayLogo.png"
import ChooseCategoryInModal from "../Payments/PaymentFields"
import {
  categoryListStore,
  categorySettingsStore,
  getSyncStoredItem,
  setSyncStoredItem,
} from "@cashbook/data-store/storage"

export default function OverallBusinessPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading business expenses..." />}
        traceId="loading_business_expenses"
      >
        <OverallBusiness key={businessId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

const MAX_CAN_COPY = 100

const PAYMENT_STATUS = [
  {
    id: "all",
    label: "All",
  },
  {
    id: "SUCCESS",
    label: "Success",
  },
  {
    id: "PENDING",
    label: "Pending",
  },
  {
    id: "REFUNDED",
    label: "Refunded",
  },
]

const PAYMENT_ATTACHMENTS = [
  {
    id: "attached",
    label: "Bill Attached",
  },
  {
    id: "missing",
    label: "Bill Missing",
  },
]

function OverallBusiness({ businessId }: { businessId: string }) {
  const { user } = useProfile()

  const { business, businessTeam, getTeamMemberInfoForId } =
    useBusiness(businessId)

  const { deactivatedWallets } = useDeactivatedWallets(businessId)

  return (
    <Box position="relative">
      <PageMeta>
        <title> All Transactions (Payments) - {business.name}</title>
      </PageMeta>
      <BusinessTransactions
        user={user}
        business={business}
        businessTeam={businessTeam}
        deactivatedWallets={deactivatedWallets}
        getTeamMemberInfoForId={getTeamMemberInfoForId}
      />
    </Box>
  )
}

function BusinessTransactions({
  user,
  business,
  businessTeam,
  deactivatedWallets,
  getTeamMemberInfoForId,
}: {
  user: TUser
  business: TBusiness
  businessTeam: TBusinessMember[]
  deactivatedWallets: DeactivatedWallet[]
  getTeamMemberInfoForId: (
    teamMemberId: string | null | undefined
  ) => TBusinessMember | null
}) {
  const [parties, setParties] = useState<PartyForFilters[]>([])
  const [selectedTransactions, setSelectedTransactions] = useState<string[]>([])
  const [partiesFetched, setPartiesFetched] = useState<boolean>(false)

  const { id, name } = business

  const { fetchParties } = useFetchParties(id)
  const { status: reportStatus, downloadReport } =
    useDownloadBusinessTransactionsReport()

  const categoriesListMappedWithBusinessId = useSyncExternalStore(
    categoryListStore.subscribe,
    categoryListStore.getCategorySettings
  )

  const categoriesList = categoriesListMappedWithBusinessId[business.id]

  const categorySettingsMappedWithBusinessId = useSyncExternalStore(
    categorySettingsStore.subscribe,
    categorySettingsStore.getCategorySettings
  )

  const isCategoryDisabled =
    categorySettingsMappedWithBusinessId[business.id]?.disabled

  const categoriesForFilter: TSelectableBaseOption[] = useMemo(() => {
    const categoryListForFilter: TSelectableBaseOption[] = [
      {
        id: "0",
        label: "No Category",
      },
    ]
    categoriesList &&
      categoriesList.forEach((category) => {
        if (category.id) {
          categoryListForFilter.push({
            id: category.id,
            label: category.name,
          })
        }
      })
    return categoryListForFilter
  }, [categoriesList])

  const fetchPartiesForFilter = useCallback(
    async (q: string) => {
      if (partiesFetched) {
        return parties.length
          ? parties
              .filter((party) =>
                party.name.toLowerCase().includes(q.toLowerCase())
              )
              .map((party) => ({
                id: party?.address || "",
                name: party.name,
                label: party.name,
                ids: party.ids,
              }))
          : []
      }
      const { data } = await fetchParties()
      setParties(data)
      setPartiesFetched(true)
      return data.length
        ? data.map((party) => ({
            id: party?.address || "",
            name: party.name,
            label: party.name,
            ids: party.ids,
          }))
        : []
    },
    [fetchParties, parties, partiesFetched]
  )

  const membersForFilters = useMemo(() => {
    const existingWallets = businessTeam
      .filter((each) => each.wallet)
      .map((each) => {
        return {
          id: each.id,
          name: each.name,
          label: each.id === user.uid ? `You (${each.name})` : each.name,
          phoneNumber: each?.phoneNumber || "",
        }
      })
    const inactiveWallets = deactivatedWallets.map((each) => {
      return {
        id: each.id,
        name: `${each.full_name} (Deactivated)`,
        label: `${each.full_name} (Deactivated)`,
        phoneNumber: "",
        businessUserId: each.id,
      }
    })
    return [...existingWallets, ...inactiveWallets] as Array<
      TSelectableBaseOption & {
        businessUserId?: string
      }
    >
  }, [businessTeam, deactivatedWallets, user.uid])

  const {
    status,
    error,
    params,
    fetching,
    lastPage,
    fetchingMore,
    transactions,
    totalExpenses,
    totalCollections,
    areFiltersApplied,
    transactionsCount,
    current: currentPage,
    paymentCollectionsEnabled,
    fetchMore,
    refreshPage,
    resetFilters,
    handleDateChange,
    handleParamChange,
  } = useOverallBusinessTransactions(id)

  const { from_datetime, to_datetime } = params

  async function downloadReportClick(
    type: "pdf" | "csv",
    columns: PdfColumns | null = null
  ) {
    const fileName = `${name} ${formatDate(new Date(), "dd-MM-yyyy")}@${
      config.appTitle
    }`
    const payloadForEvent: {
      reportType: "pdf" | "csv"
      dateFilterApplied?: boolean
      memberFilterApplied?: boolean
      billProofFilterApplied?: boolean
      transactionStatusFilterApplied?: boolean
    } = {
      reportType: type,
    }
    const payload: DownloadBusinessTransactionsReportFilters = {
      reportType: type,
    }
    if (params.from_datetime) {
      payloadForEvent.dateFilterApplied = true
      payload.from_datetime = params.from_datetime
    }
    if (params.to_datetime) {
      payload.to_datetime = params.to_datetime
    }
    if (params.status) {
      payloadForEvent.transactionStatusFilterApplied = true
      payload.status = params.status
    }
    if (params.member) {
      payloadForEvent.memberFilterApplied = true
      payload.memberId = params.member.id
    }
    if (params.party) {
      payload.partyId = params.party.ids.toString()
    }
    if (params.attachments?.id) {
      payloadForEvent.billProofFilterApplied = true
      payload.attachments = params.attachments.id
    }
    trackEvent(TrackingEvents.PAYMENTS_REPORT_DOWNLOADED, payloadForEvent)
    await downloadReport(
      id,
      fileName,
      payload,
      !!paymentCollectionsEnabled,
      columns
    )
  }

  const pdfReportLoading =
    reportStatus.loading === "in_progress" && reportStatus.type === "pdf"
  const excelReportLoading =
    reportStatus.loading === "in_progress" && reportStatus.type === "csv"

  const npciTransactionIds = useMemo(() => {
    const npciTransactionIds: string[] = []
    selectedTransactions.forEach((transactionId) => {
      const transactionItem = transactions?.find(
        (transaction) => transaction.id === transactionId
      )
      if (transactionItem?.npci_txn_id) {
        npciTransactionIds.push(transactionItem?.npci_txn_id)
      }
    })
    return npciTransactionIds
  }, [selectedTransactions, transactions])

  const { allowedToCopy, allowedToUpdateCategory } = useMemo(() => {
    let areSelectedTransactionsEligibleToCopy = true
    let areSelectedTransactionsToSetCategory = true
    if (!selectedTransactions.length) {
      return {
        allowedToCopy: false,
        allowedToUpdateCategory: false,
      }
    }
    selectedTransactions.forEach((transactionId) => {
      const transactionItem = transactions?.find(
        (transaction) => transaction.id === transactionId
      )
      if (transactionItem) {
        if (
          !allowedStatusForCopyTransactions.includes(transactionItem.status)
        ) {
          areSelectedTransactionsEligibleToCopy = false
        }
        if (
          !allowedStatusForSetExtraInfoTransactions.includes(
            transactionItem.status
          )
        ) {
          areSelectedTransactionsEligibleToCopy = false
        }
      } else {
        areSelectedTransactionsEligibleToCopy = false
        areSelectedTransactionsToSetCategory = false
      }
    })

    return {
      allowedToCopy: areSelectedTransactionsEligibleToCopy,
      allowedToUpdateCategory: areSelectedTransactionsToSetCategory,
    }
  }, [selectedTransactions, transactions])

  return (
    <>
      <Stack
        key={id}
        bgColor="surfaceDefault"
        gap="8"
        paddingY="6"
        height="full"
        paddingX={{ xs: "4", md: "8" }}
        minHeight={{ xs: "screen", sm: "0" }}
      >
        {status === "in_progress" ? (
          <Stack gap="8">
            <Inline gap="6">
              <SkeletonFilterButton />
              <SkeletonFilterButton />
              <SkeletonFilterButton />
              <SkeletonFilterButton />
              <SkeletonFilterButton />
              <SkeletonFilterButton />
            </Inline>
            <Inline alignItems="center" justifyContent="between" gap="6">
              <Inline
                borderWidth="1"
                borderColor="borderOutline"
                rounded="md"
                paddingY="4"
                paddingX="6"
                justifyContent="between"
                maxWidth="2xl"
                width="full"
              >
                <Stack gap="2" width="full" maxWidth="xs">
                  <SkeletonTitle height="3" />
                  <SkeletonTitle width="full" />
                </Stack>
              </Inline>
              <Inline
                borderWidth="1"
                borderColor="borderOutline"
                rounded="md"
                paddingY="4"
                paddingX="6"
                justifyContent="between"
                maxWidth="sm"
                width="full"
              >
                <Stack gap="2" width="full" maxWidth="xs">
                  <SkeletonTitle height="3" />
                  <SkeletonTitle width="full" />
                </Stack>
              </Inline>
            </Inline>
            <Box
              as="table"
              rounded="md"
              borderWidth="1"
              borderColor="borderOutline"
            >
              <SkeletonRows numOfRows={10} numOfCols={7} />
            </Box>
          </Stack>
        ) : error ? (
          <Box height="full">
            <GeneralErrorHandler onRetry={refreshPage} />
          </Box>
        ) : (
          <Box>
            <Stack gap="6">
              <Inline alignItems="center" gap="6" flexWrap="wrap">
                <Box>
                  <DateSelectForPayments
                    to_datetime={to_datetime}
                    from_datetime={from_datetime}
                    onChange={(from, to, label) => {
                      handleDateChange([from, to], label || "all")
                    }}
                  />
                </Box>
                <Box>
                  <SearchSelect
                    searchDisabled
                    hasValue={Boolean(params.status)}
                    onChange={(option) => {
                      handleParamChange(
                        "status",
                        option && option.id !== "all" ? option.id : undefined
                      )
                    }}
                    options={PAYMENT_STATUS}
                    value={params.status ? params.status : "all"}
                    label={
                      !params.status
                        ? "Status: All"
                        : params.status === "SUCCESS"
                        ? "Success"
                        : params.status === "REFUNDED"
                        ? "Refunded"
                        : "Pending"
                    }
                  />
                </Box>
                <Box>
                  <SearchSelect
                    label={
                      !params.categories
                        ? "Payment Category"
                        : `${params.categories.label}`
                    }
                    searchPlaceholder="Search Categories"
                    onChange={(option) => {
                      if (option?.id !== params.categories?.id) {
                        handleParamChange(
                          "categories",
                          option
                            ? {
                                id: option.id,
                                label: option.label,
                              }
                            : undefined
                        )
                      }
                    }}
                    options={categoriesForFilter}
                    value={params.categories ? params.categories.id : null}
                  />
                </Box>
                <Box>
                  <SearchSelect
                    label={
                      !params.member ? "Members: All" : `${params.member.name}`
                    }
                    searchPlaceholder="Search Members"
                    onChange={(option) => {
                      if (option?.id !== params.member?.id) {
                        handleParamChange(
                          "member",
                          option
                            ? {
                                id: option.id,
                                name: option.label,
                                businessUserId: option?.businessUserId,
                              }
                            : undefined
                        )
                      }
                    }}
                    options={membersForFilters}
                    value={params.member ? params.member.id : null}
                  />
                </Box>
                <Box>
                  <SearchSelect
                    label={
                      !params.party
                        ? "Parties: All"
                        : `${params.party?.name || ""}`
                    }
                    searchPlaceholder="Search Parties"
                    onChange={(option) => {
                      handleParamChange(
                        "party",
                        option
                          ? {
                              id: option.id,
                              name: option.name,
                              ids: option.ids,
                            }
                          : undefined
                      )
                    }}
                    fetchOptions={(q: string) => fetchPartiesForFilter(q)}
                    value={params.party ? params.party.id : null}
                  />
                </Box>
                <Box>
                  <SearchSelect
                    searchDisabled
                    hasValue={Boolean(params.attachments?.id)}
                    label={
                      !params.attachments
                        ? "Bill: All"
                        : `${params.attachments.label || ""}`
                    }
                    onChange={(option) => {
                      handleParamChange(
                        "attachments",
                        option ? option : undefined
                      )
                    }}
                    options={PAYMENT_ATTACHMENTS}
                    value={params.attachments ? params.attachments : null}
                  />
                </Box>
                {areFiltersApplied ? (
                  <Box>
                    <Button level="tertiary" inline onClick={resetFilters}>
                      <CancelIcon />
                      Clear All
                    </Button>
                  </Box>
                ) : null}
              </Inline>
              <Inline
                gap="6"
                flexDirection={{ lg: "row", md: "col" }}
                alignItems={{ lg: "center", md: "start" }}
              >
                <Stack
                  gap="2"
                  paddingX="6"
                  rounded="md"
                  width="full"
                  justifyContent="center"
                  className="h-20"
                  backgroundColor="backgroundLight3"
                >
                  <Inline alignItems="center">
                    <Stack>
                      <Inline fontSize="c2" gap="2" alignItems="center">
                        <Text>Total Expenses</Text>
                      </Inline>
                      <Amount
                        fontSize="s1"
                        currency="inr"
                        color="textCashOut"
                        amount={
                          totalExpenses && totalExpenses > 0 ? totalExpenses : 0
                        }
                      />
                    </Stack>
                    <Box
                      borderWidth="1"
                      borderColor="borderDividers"
                      marginX="12"
                      height="full"
                    />
                    <Stack>
                      <Inline fontSize="c2" gap="2" alignItems="center">
                        <Text>Total Collections</Text>
                      </Inline>
                      <Amount
                        fontSize="s1"
                        currency="inr"
                        color="textCashIn"
                        amount={
                          totalCollections && totalCollections > 0
                            ? totalCollections
                            : 0
                        }
                      />
                    </Stack>
                  </Inline>
                </Stack>
                <Inline
                  gap="4"
                  rounded="md"
                  paddingX="6"
                  minWidth="max"
                  className="h-20 xl:w-fit xs:w-full"
                  alignItems="center"
                  backgroundColor="backgroundLight3"
                >
                  <Box backgroundColor="surfaceDefault" rounded="md">
                    <ExportTransactionsInModal
                      categoriesEnabled={!isCategoryDisabled}
                      pdfDownloadLoading={pdfReportLoading}
                      downloadPdf={downloadReportClick}
                    >
                      {({ onExport }) => (
                        <Button
                          size="lg"
                          disabled={
                            pdfReportLoading ||
                            excelReportLoading ||
                            !transactions?.length
                          }
                          onClick={onExport}
                        >
                          <Box>
                            {pdfReportLoading ? <SpinnerIcon /> : <PDFIcon />}
                          </Box>
                          PDF{" "}
                          <Text
                            as="span"
                            className="xl:block lg:hidden xs:block"
                          >
                            Report
                          </Text>
                        </Button>
                      )}
                    </ExportTransactionsInModal>
                  </Box>
                  <Button
                    size="lg"
                    level="primary"
                    disabled={
                      pdfReportLoading ||
                      excelReportLoading ||
                      !transactions?.length
                    }
                    onClick={() => downloadReportClick("csv")}
                  >
                    <Box>
                      {excelReportLoading ? <SpinnerIcon /> : <ExcelFileIcon />}
                    </Box>
                    Excel{" "}
                    <Text as="span" className="xl:block lg:hidden xs:block">
                      Report
                    </Text>
                  </Button>
                </Inline>
              </Inline>
              <Stack gap="4">
                {fetching ? (
                  <SkeletonTitle />
                ) : (
                  <Box marginTop="2">
                    <Text color="textMedium" fontSize="b3">
                      Showing {transactions?.length ? 1 : 0}-
                      {transactions?.length} of {transactionsCount}{" "}
                      {pluralize("transaction", transactionsCount)}
                    </Text>
                  </Box>
                )}
                <Box as="table" width="full" position="relative">
                  <Box as="thead">
                    <tr>
                      <Box
                        className="w-[24px] z-[1] "
                        as="th"
                        position="sticky"
                        bgColor="backgroundLight3"
                        paddingX="3"
                        paddingY="4"
                        top="0"
                      />
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        bgColor="backgroundLight3"
                        className={`w-[130px]`}
                      >
                        <Text fontSize="s4">Date & Time</Text>
                      </Box>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        className="z-[1] w-[152px]"
                        bgColor="backgroundLight3"
                      >
                        <Inline justifyContent="end" paddingX="2">
                          <Text fontSize="s4">Transaction ID</Text>
                        </Inline>
                      </Box>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        className="min-w-[40px]"
                        bgColor="backgroundLight3"
                      >
                        <Text fontSize="s4">Category</Text>
                      </Box>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        bgColor="backgroundLight3"
                      >
                        <Text fontSize="s4">Party</Text>
                      </Box>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        className={`min-w-[150px]`}
                        bgColor="backgroundLight3"
                      >
                        <Text fontSize="s4">Member</Text>
                      </Box>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        bgColor="backgroundLight3"
                      >
                        <Inline justifyContent="end">
                          <Text fontSize="s4">Amount</Text>
                        </Inline>
                      </Box>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        className="z-[1]"
                        bgColor="backgroundLight3"
                      >
                        <Inline justifyContent="center">
                          <Text fontSize="s4">Bill</Text>
                        </Inline>
                      </Box>
                    </tr>
                  </Box>
                  {fetching ? (
                    <SkeletonRows numOfRows={10} numOfCols={7} />
                  ) : (
                    <Box as="tbody">
                      {transactions?.length ? (
                        transactions.map((transaction, i) => (
                          <TransactionItem
                            businessId={business.id}
                            user={user}
                            isLastTransaction={i === transactions.length - 1}
                            transaction={transaction}
                            key={transaction.id}
                            isSelected={selectedTransactions.includes(
                              transaction.id
                            )}
                            onTransactionSelect={(tId) => {
                              if (selectedTransactions.includes(tId)) {
                                setSelectedTransactions((prev) =>
                                  prev.filter((t) => t !== tId)
                                )
                                return
                              }
                              if (
                                selectedTransactions.length > MAX_CAN_COPY ||
                                selectedTransactions.length + 1 > MAX_CAN_COPY
                              ) {
                                toast.error(
                                  `Cannot select more than ${MAX_CAN_COPY} transactions`
                                )
                                setSelectedTransactions((prev) =>
                                  prev.splice(0, MAX_CAN_COPY)
                                )
                                return
                              }
                              setSelectedTransactions((prev) => [...prev, tId])
                            }}
                            getBusinessUser={getTeamMemberInfoForId}
                          />
                        ))
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              paddingY="24"
                            >
                              <Stack
                                gap="2"
                                textAlign="center"
                                alignItems="center"
                                justifyContent="center"
                              >
                                {areFiltersApplied ? (
                                  <Box marginBottom="4">
                                    <Circle size="16">
                                      <AddMoneyIcon size="8" />
                                    </Circle>
                                  </Box>
                                ) : null}
                                <Text fontSize="s1">No transactions found</Text>
                                <Text fontSize="b3" color="textMedium">
                                  {areFiltersApplied
                                    ? "Try with different filter options"
                                    : "Make payments from your wallet on CashBook App to see all transactions"}
                                </Text>
                                {areFiltersApplied ? (
                                  <Box marginTop="6">
                                    <Button
                                      level="primary"
                                      onClick={resetFilters}
                                    >
                                      <CancelIcon />
                                      Clear Filters
                                    </Button>
                                  </Box>
                                ) : null}
                              </Stack>
                            </Box>
                          </td>
                        </tr>
                      )}
                    </Box>
                  )}
                </Box>
                <Inline
                  alignItems="center"
                  justifyContent={
                    !transactions?.length || currentPage === lastPage
                      ? "center"
                      : "between"
                  }
                >
                  {!transactions?.length || currentPage === lastPage ? null : (
                    <Box>
                      <Button
                        level="primary"
                        onClick={fetchMore}
                        disabled={fetchingMore}
                      >
                        <Text fontSize="bt">Show More Transactions</Text>
                        {fetchingMore && <SpinnerIcon />}
                      </Button>
                    </Box>
                  )}
                  <Box style={{ width: 140, height: 32 }} alignSelf="center">
                    <img src={ObopayLogo} alt={`Obopay Logo`} />
                  </Box>
                </Inline>
              </Stack>
            </Stack>
          </Box>
        )}
      </Stack>
      {selectedTransactions.length ? (
        <Inline
          position="sticky"
          backgroundColor="white"
          bottom="0"
          width="full"
          paddingY="6"
          paddingX="8"
          borderTopWidth="1"
          borderColor="borderDividers"
          alignItems="center"
          justifyContent="start"
        >
          <CBButton
            level="secondary"
            size="lg"
            iconPlacement="left"
            onClick={() => {
              setSelectedTransactions([])
            }}
          >
            Unselect All
          </CBButton>
          <Inline marginLeft="4">
            <Text fontSize="b2">
              <Text as="span" fontSize="b1">
                {selectedTransactions.length}{" "}
                {pluralize("transaction", selectedTransactions.length)}
              </Text>{" "}
              selected
            </Text>
          </Inline>
          <Inline gap="6" justifyContent="end" alignItems="center" flex="1">
            {!isCategoryDisabled ? (
              <ChooseCategoryInModal
                source="businessTransactions"
                businessId={business.id}
                npciTranscationIds={npciTransactionIds}
                onSuccess={refreshPage}
              >
                {({ handleAction }) => (
                  <Box cursor="pointer">
                    <CBButton
                      type="button"
                      onClick={() => {
                        if (allowedToUpdateCategory) {
                          handleAction("copy")
                        } else {
                          toast.error(
                            "You can only update category in Successfull, Refunded, Pending and Deemed Transactions"
                          )
                        }
                      }}
                      size="lg"
                    >
                      Update Category
                    </CBButton>
                  </Box>
                )}
              </ChooseCategoryInModal>
            ) : null}
            <CopyTransactionsToCashbook
              businessId={business.id}
              transactions={
                transactions?.length
                  ? transactions?.filter((t) =>
                      selectedTransactions.includes(t.id)
                    )
                  : []
              }
            >
              {({ open }) => (
                <CBButton
                  level="primary"
                  size="lg"
                  iconPlacement="left"
                  onClick={() => {
                    if (allowedToCopy) {
                      open()
                      trackEvent(
                        TrackingEvents.PAYMENTS_COPY_TO_CASHBOOK_INITIATED,
                        { from: "multiSelect", screen: "businessTransactions" }
                      )
                    } else {
                      toast.error(
                        "Only Successful and Refunded transactions can be copied"
                      )
                    }
                  }}
                >
                  <CopyIcon />
                  Copy To Cashbook
                </CBButton>
              )}
            </CopyTransactionsToCashbook>
          </Inline>
        </Inline>
      ) : null}
    </>
  )
}

function SkeletonFilterButton() {
  return (
    <Inline
      borderWidth="1"
      paddingX="3"
      paddingY="2"
      rounded="md"
      alignItems="center"
      gap="3"
      borderColor={"borderOutline"}
    >
      <SkeletonTitle />
    </Inline>
  )
}

type COLUMN_NAMES =
  | "dateTime"
  | "transactionId"
  | "mode"
  | "party"
  | "member"
  | "amount"
  | "bill"
  | "category"

function TransactionItem({
  user,
  isSelected,
  transaction,
  disabled_columns,
  isLastTransaction,
  getBusinessUser,
  onTransactionSelect,
  businessId,
}: {
  user: TUser
  isLastTransaction?: boolean
  transaction: PaymentsTransaction
  disabled_columns?: COLUMN_NAMES[]
  isSelected?: boolean
  onTransactionSelect?: (tId: string) => void
  getBusinessUser?: (id: string) => TBusinessUser | null
  businessId: string
}) {
  const {
    timestamp,
    party,
    created_at,
    id,
    type,
    status,
    amount,
    user_meta,
    ext_party_fid,
    attachments,
    transaction_type,
    business_id,
    created_by,
    user_id,
    npci_txn_id,
    metadata,
  } = transaction

  const [numOfAttachments, setNumOfAttachmentIds] = useState<number>(
    attachments?.length || 0
  )

  const transactionId = npci_txn_id

  const categoriesListMappedWithBusinessId = useSyncExternalStore(
    categoryListStore.subscribe,
    categoryListStore.getCategorySettings
  )

  const categoriesList = categoriesListMappedWithBusinessId[businessId]

  const categoryName = useMemo(() => {
    const categoryName =
      categoriesList.find(
        (category) =>
          metadata?.category_id && category.id === metadata?.category_id
      )?.name || ""
    return categoryName
  }, [categoriesList, metadata?.category_id])

  const partyInvolved: {
    id: string
    name: string
    type: "individual" | "merchant"
  } = useMemo(() => {
    return {
      id: party?.id || id,
      name: party?.name || "",
      type: party?.entity_type === "ENTITY" ? "merchant" : "individual",
    }
  }, [id, party?.entity_type, party?.id, party?.name])

  const memberOrStaffInvolved: {
    id: string
    name: string
  } = useMemo(() => {
    const member = getBusinessUser?.(
      user_meta?.uid || created_by || ext_party_fid || user_id || ""
    )
    return {
      id: member?.id || user_meta?.uid || "",
      name:
        (member?.id || user_meta?.uid) === user.uid
          ? `You (${member?.name || user_meta?.name})`
          : `${member?.name || user_meta?.name || ""}`,
    }
  }, [ext_party_fid, getBusinessUser, user.uid, user_meta, user_id, created_by])

  function onAttachmentAdded() {
    setNumOfAttachmentIds((prevCount) => {
      return prevCount + 1
    })
  }

  function onAttachmentDeleted() {
    setNumOfAttachmentIds((prevCount) => {
      if (prevCount > 0) return prevCount - 1
      return 0
    })
  }

  return (
    <TransactionDetailsInModal
      transactionId={id}
      npciTransactionId={npci_txn_id}
      isBillAttached={numOfAttachments > 0}
      transaction_type={transaction_type}
      businessId={business_id || "missing"}
      onAttachmentAdded={onAttachmentAdded}
      onAttachmentDeleted={onAttachmentDeleted}
    >
      {({ open }) => (
        <Box
          as="tr"
          borderBottomWidth={isLastTransaction ? "0" : "1"}
          className={classNames("group z-0", {
            "bg-[#B6C1EE]": isSelected,
            "hover:bg-gray-100 ": !isSelected,
          })}
          cursor="pointer"
          onClick={open}
        >
          <Box
            className="w-[24px] z-[1]"
            as="th"
            paddingX="3"
            paddingY="4"
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation()
              onTransactionSelect?.(id)
            }}
          >
            <Inline gap="2" justifyContent="center" alignItems="center">
              <Checkbox size="4" isSelected={isSelected} />
            </Inline>
          </Box>
          {disabled_columns?.includes("dateTime") ? null : (
            <Box as="td" paddingX="3" paddingY="4" className="whitespace-pre">
              <Stack gap="2">
                <TransactionDate
                  fontSize="b3"
                  timeStamp={transformDate(timestamp || created_at)}
                />
                <Time
                  fontSize="c2"
                  color="textMedium"
                  timeStamp={transformDate(timestamp || created_at)}
                  format="hh:mm a"
                />
                {status !== "SUCCESS" &&
                status !== "REFUNDED" &&
                status !== "DEEMED" ? (
                  <Inline
                    gap="1"
                    alignItems="center"
                    color={getTextColorForStatus({ status })}
                  >
                    {getHelperIconForStatus({ status, size: "4" })}
                    <Text fontSize="c2">
                      {getStatusTitle({ status, type })}
                    </Text>
                  </Inline>
                ) : null}
              </Stack>
            </Box>
          )}
          {!disabled_columns?.includes("transactionId") && transactionId ? (
            <Box as="td" paddingX="3" paddingY="4" className="whitespace-pre">
              <Inline gap="2" zIndex="0" justifyContent="end" paddingX="2">
                <Tooltip event="onHover" content={transactionId}>
                  <Text
                    color="textHigh"
                    fontSize="b3"
                    className="line-clamp-1 break-all"
                  >
                    ...
                    {transactionId.substring(
                      transactionId.length - 10,
                      transactionId.length
                    ) || "N/A"}
                  </Text>
                </Tooltip>
                <CopyToClipboard>
                  {({ copy, copied }) => (
                    <Box
                      onClick={(e: SyntheticEvent) => {
                        e.stopPropagation()
                        copy(transactionId)
                        toast.success("Transaction ID copied to clipboard")
                      }}
                    >
                      <Tooltip event="onHover" content={"Copy to Clipboard"}>
                        {copied ? (
                          <CheckIcon size="4" color="iconSuccess" />
                        ) : (
                          <CopyIcon size="4" color="iconMedium" />
                        )}
                      </Tooltip>
                    </Box>
                  )}
                </CopyToClipboard>
              </Inline>
            </Box>
          ) : null}
          {disabled_columns?.includes("category") ? null : (
            <Box as="td" paddingX="3" paddingY="4" className="whitespace-pre">
              {categoryName ? (
                <Text fontSize="b3" className="line-clamp-1">
                  {categoryName}
                </Text>
              ) : (
                <Text fontSize="b3" className="line-clamp-1">
                  --
                </Text>
              )}
            </Box>
          )}
          {disabled_columns?.includes("party") ? null : (
            <Box as="td" paddingX="3" paddingY="4" className="max-w-[180px]">
              {partyInvolved.name.length ? (
                <Inline alignItems="center" gap="2">
                  <Avatar
                    id={partyInvolved.id}
                    name={partyInvolved.name}
                    size="6"
                    fontSize="c2"
                    type={partyInvolved.type}
                  />
                  <Text fontSize="b3" className="line-clamp-1">
                    {partyInvolved.name}
                  </Text>
                </Inline>
              ) : null}
            </Box>
          )}
          {disabled_columns?.includes("member") ? null : (
            <Box as="td" paddingX="3" paddingY="4" className="max-w-[180px]">
              {memberOrStaffInvolved.name?.length ? (
                <Inline alignItems="center" gap="2">
                  <Avatar
                    id={memberOrStaffInvolved.id}
                    name={memberOrStaffInvolved.name}
                    size="6"
                    fontSize="c2"
                  />
                  <Text fontSize="b3" className="line-clamp-1">
                    {memberOrStaffInvolved.name}
                  </Text>
                </Inline>
              ) : (
                <Text fontSize="b3" className="line-clamp-1">
                  --
                </Text>
              )}
            </Box>
          )}
          {disabled_columns?.includes("amount") ? null : (
            <Box as="td" paddingX="2" paddingY="5">
              <Inline justifyContent="end" paddingX="2">
                <Amount
                  type={transaction.type === "DEBIT" ? "cash-out" : "cash-in"}
                  amount={Number(amount)}
                  currency="inr"
                  fontWeight="semibold"
                />
              </Inline>
            </Box>
          )}
          {disabled_columns?.includes("bill") ? null : (
            <Box
              as="td"
              paddingX="3"
              paddingY="5"
              className="align-middle"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {status === "SUCCESS" || status === "DEEMED" ? (
                <>
                  {numOfAttachments > 0 ? (
                    <Tooltip event="onHover" content={"Bill Attached"}>
                      <Box size="8">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 33 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M26.7144 4.66669L24.7144 2.66669L22.7144 4.66669L20.7144 2.66669L18.7144 4.66669L16.7144 2.66669L14.7144 4.66669L12.7144 2.66669L10.7144 4.66669L8.71436 2.66669L6.71436 4.66669L4.71436 2.66669V29.3334L6.71436 27.3334L8.71436 29.3334L10.7144 27.3334L12.7144 29.3334L14.7144 27.3334L16.7144 29.3334L18.7144 27.3334L20.7144 29.3334L22.7144 27.3334L24.7144 29.3334L26.7144 27.3334L28.7144 29.3334V2.66669L26.7144 4.66669ZM26.0477 25.4534H7.38102V6.54669H26.0477V25.4534ZM8.71436 20H24.7144V22.6667H8.71436V20ZM8.71436 14.6667H24.7144V17.3334H8.71436V14.6667ZM8.71436 9.33335H24.7144V12H8.71436V9.33335Z"
                            fill="#757575"
                          />
                        </svg>
                      </Box>
                    </Tooltip>
                  ) : (
                    <Tooltip event="onHover" content={"Bill Missing"}>
                      <Box>
                        <svg
                          height="33"
                          width="32"
                          viewBox="0 0 33 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.1428 7.98658L26.1828 25.3332H6.10282L16.1428 7.98658ZM3.79615 23.9999C2.76949 25.7732 4.04949 27.9999 6.10282 27.9999H26.1828C28.2362 27.9999 29.5162 25.7732 28.4895 23.9999L18.4495 6.65324C17.4228 4.87991 14.8628 4.87991 13.8362 6.65324L3.79615 23.9999ZM14.8095 14.6666V17.3332C14.8095 18.0666 15.4095 18.6666 16.1428 18.6666C16.8762 18.6666 17.4762 18.0666 17.4762 17.3332V14.6666C17.4762 13.9332 16.8762 13.3332 16.1428 13.3332C15.4095 13.3332 14.8095 13.9332 14.8095 14.6666ZM14.8095 21.3332H17.4762V23.9999H14.8095V21.3332Z"
                            fill="#BD610D"
                          />
                        </svg>
                      </Box>
                    </Tooltip>
                  )}
                </>
              ) : null}
            </Box>
          )}
        </Box>
      )}
    </TransactionDetailsInModal>
  )
}

const pdfColumnOptions: {
  id: keyof PdfColumns
  title: string
  isRequired: boolean
  isChecked: boolean
}[] = [
  {
    id: "date",
    title: "Date",
    isRequired: true,
    isChecked: true,
  },
  {
    id: "expenses",
    title: "Expenses",
    isRequired: true,
    isChecked: true,
  },
  {
    id: "refunds",
    title: "Refunds",
    isRequired: true,
    isChecked: true,
  },
  {
    id: "collections",
    title: "Collections",
    isRequired: true,
    isChecked: true,
  },
  {
    id: "remark",
    title: "Remark",
    isRequired: false,
    isChecked: true,
  },
  {
    id: "note",
    title: "Additional Note",
    isRequired: false,
    isChecked: true,
  },
  {
    id: "party",
    title: "Party",
    isRequired: false,
    isChecked: true,
  },
  {
    id: "member",
    title: "Member",
    isRequired: false,
    isChecked: true,
  },
  {
    id: "category",
    title: "Category",
    isRequired: false,
    isChecked: true,
  },
]

export function ExportTransactionsInModal({
  children,
  categoriesEnabled,
  pdfDownloadLoading,
  downloadPdf,
}: {
  children: (props: { onExport: () => void }) => React.ReactNode
  categoriesEnabled: boolean
  pdfDownloadLoading: boolean
  downloadPdf: (type: "pdf" | "csv", columns: PdfColumns) => void
}) {
  const state = useOverlayTriggerState({})
  const [pdfColumns, setPdfColumns] = useState<
    {
      id: keyof PdfColumns
      title: string
      isRequired: boolean
      isChecked: boolean
    }[]
  >(() => {
    const columns = getSyncStoredItem("pdfColumns")
    return columns || pdfColumnOptions
  })

  return (
    <>
      {children({
        onExport: () => {
          state.open()
        },
      })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title="Export Transactions"
        size="lg"
        placement="right"
      >
        <SuspenseWithPerf
          traceId="fetch_transactions_for_export"
          fallback={<DataLoadingFallback label="Loading entries..." />}
        >
          <ModalBody className="relative">
            <Stack gap="8">
              <Stack gap="8">
                <Stack gap="4">
                  <Text>Select columns you wish to include</Text>
                  <Stack gap="12">
                    <Inline as="ol" gap="4" flexWrap="wrap">
                      {pdfColumns.map(
                        ({ id, title, isRequired, isChecked }, index) => {
                          return id === "category" &&
                            !categoriesEnabled ? null : (
                            <Box
                              as="li"
                              borderWidth="1"
                              rounded="md"
                              className={`border border-[#E0E0E0]`}
                              key={id}
                              bgColor={
                                isRequired
                                  ? "gray100"
                                  : isChecked
                                  ? "blue100"
                                  : "white"
                              }
                              style={{ width: "30%" }}
                            >
                              <Inline
                                as="label"
                                paddingX="6"
                                paddingY="4"
                                justifyContent="between"
                                width="full"
                                cursor="pointer"
                              >
                                <Inline gap="2">
                                  <input
                                    disabled={isRequired}
                                    type="checkbox"
                                    value={id}
                                    id={`settings_${id}`}
                                    checked={isChecked}
                                    name="settings"
                                    className={classNames(
                                      "block rounded bg:gray-100 placeholder-gray-500 border-2 border-gray-500",
                                      "form-checkbox"
                                    )}
                                    onChange={() => {
                                      const optionsClone = [...pdfColumns]
                                      optionsClone[index] = {
                                        ...optionsClone[index],
                                        isChecked:
                                          !optionsClone[index].isChecked,
                                      }
                                      setPdfColumns(optionsClone)
                                    }}
                                  />
                                  <Text>{title}</Text>
                                </Inline>
                                {isRequired && (
                                  <Text fontSize="sm">Compulsory</Text>
                                )}
                              </Inline>
                            </Box>
                          )
                        }
                      )}
                    </Inline>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            {pdfDownloadLoading ? (
              <Button status="primary" disabled size="lg">
                <SpinnerIcon /> Please wait...
              </Button>
            ) : (
              <Button
                level="primary"
                autoFocus
                size="lg"
                onClick={() => {
                  const options: PdfColumns = {
                    remark: true,
                    note: true,
                    party: false,
                    member: false,
                    category: false,
                  }
                  pdfColumns.forEach((column) => {
                    if (Object.keys(options).includes(column.id)) {
                      options[column.id] = column.isChecked
                    }
                  })
                  setSyncStoredItem("pdfColumns", pdfColumns)
                  downloadPdf("pdf", options)
                }}
              >
                <DocumentDownloadIcon /> Download as PDF
              </Button>
            )}
          </ModalFooter>
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}
