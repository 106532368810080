import { pluralize, useMount } from "@cashbook/util-general"
import {
  DataLoadingFallback,
  PageMeta,
  Text,
  Heading,
  Box,
  Stack,
  Inline,
  Switch,
  PlusIcon,
  Button,
  DotsVerticalIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  LabelIcon,
  Circle,
  UsersFilledIcon,
  useOverlayTriggerState,
  Modal,
  ModalBody,
  ModalFooter,
  SpinnerIcon,
} from "@cashbook/web-components"
import { useMemo, useState, useSyncExternalStore } from "react"
import { useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import {
  CategorySettings,
  categorySettingsStore,
  categoryListStore,
  PaymentCategoryItem,
} from "@cashbook/data-store/storage"

import ErrorBoundary from "../ErrorBoundary"
import { SearchBar, Tag } from "../common"
import {
  toggleCategoryState,
  useEnableDisableCategory,
  useGetPaymentCategory,
  useManagePaymentCategory,
} from "@cashbook/data-store/payments"
import toast from "react-hot-toast"
import {
  AddPaymentCategoryInDialog,
  DeletePaymentCategoryInDialog,
  EditPaymentCategoryInDialog,
} from "../Payments/PaymentFields"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import { useBusiness } from "@cashbook/data-store/businesses"

export default function PaymentCategorySettingsPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details..." />}
        traceId="loading_book_details"
      >
        <PaymentCategoryCategorySettings businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function PaymentCategoryCategorySettings({
  businessId,
}: {
  businessId: string
}) {
  return (
    <BookFieldSettingsDetails businessId={businessId} fieldLabel="Category" />
  )
}

export function BookFieldSettingsDetails({
  fieldLabel,
  fieldKey,
  businessId,
}: {
  businessId: string
  fieldLabel: string
  fieldKey?: "categories" | "parties" | "paymentModes"
}) {
  const { authTeamMemberDetails } = useBusiness(businessId)
  const { isLoading, getPaymentCategorySettings } = useManagePaymentCategory(
    businessId,
    authTeamMemberDetails.role.id,
    false
  )
  const { isLoading: fetchPaymentCategoriesLoading, getPaymentCategoriesList } =
    useGetPaymentCategory(businessId, authTeamMemberDetails.role.id, false)

  const {
    enableDisableCategoryNetworkCall,
    isLoading: enableDisableCategoryLoading,
  } = useEnableDisableCategory(businessId)
  const categorySettingsMappedWithBusinessId = useSyncExternalStore(
    categorySettingsStore.subscribe,
    categorySettingsStore.getCategorySettings
  )
  const categoriesListMappedWithBusinessId = useSyncExternalStore(
    categoryListStore.subscribe,
    categoryListStore.getCategorySettings
  )

  const categoriesList = categoriesListMappedWithBusinessId[businessId]
  const categorySettings = categorySettingsMappedWithBusinessId[businessId]
  const [isCategoryFieldDisabled, setIsCategoryFieldDisabled] = useState(
    Boolean(categorySettings?.disabled)
  )
  const [isCategoryRequiredField, setIsCategoryRequiredField] = useState(
    categorySettings?.disabled ? false : Boolean(categorySettings?.required)
  )

  useMount(() => {
    if (!categorySettings) {
      getPaymentCategorySettings()
        .then((data) => {
          setIsCategoryRequiredField(
            Boolean(data?.settings?.category?.required)
          )
          setIsCategoryFieldDisabled(
            Boolean(data?.settings?.category?.disabled)
          )
        })
        .catch(() => null)
    }

    if (!categoriesList) {
      getPaymentCategoriesList().catch(() => null)
    }
  })

  const fieldDisabled = isCategoryFieldDisabled
  const [search, setSearch] = useState<string>("")

  const { filteredDefaultCategories, filteredCustomCategories } =
    useMemo(() => {
      const defaultCategories: PaymentCategoryItem[] = []
      const customCategories: PaymentCategoryItem[] = []
      categoriesList &&
        categoriesList.forEach((category) => {
          if (category.name.toLowerCase().includes(search.toLowerCase()))
            if (category.default_id) {
              defaultCategories.push(category)
            } else {
              customCategories.push(category)
            }
        })
      return {
        filteredDefaultCategories: defaultCategories,
        filteredCustomCategories: customCategories,
      }
    }, [categoriesList, search])

  const onConfirm = (
    flowName:
      | "disableField"
      | "enableField"
      | "setFieldRequired"
      | "setFieldOptional"
  ) => {
    let categorySettings: CategorySettings = {
      disabled: false,
      required: false,
    }
    if (flowName === "disableField") {
      categorySettings = {
        disabled: true,
        required: false,
      }
    } else if (flowName === "enableField") {
      categorySettings.disabled = false
    } else if (flowName === "setFieldRequired") {
      categorySettings.required = true
    } else {
      categorySettings.required = false
    }
    toggleCategoryState({
      businessId,
      ...categorySettings,
    })
      .then(() => {
        const analyticsParams: { disabled?: boolean; required?: boolean } = {}
        if (flowName === "disableField") {
          analyticsParams["disabled"] = true
        } else if (flowName === "enableField") {
          analyticsParams["disabled"] = false
        } else if (flowName === "setFieldOptional") {
          analyticsParams["required"] = false
        } else {
          analyticsParams["required"] = true
        }
        trackEvent(TrackingEvents.PAYMENT_CATEGORIES_SETTINGS_UPDATED, {
          ...analyticsParams,
        })
        categorySettingsStore.updateList(businessId, {
          ...categorySettings,
        })
        setIsCategoryFieldDisabled(categorySettings?.disabled)
        setIsCategoryRequiredField(categorySettings?.required)
        let toastMessage = "Category disabled successfully!"
        if (flowName === "enableField") {
          toastMessage = "Category enabled successfully!"
        } else if (flowName === "setFieldOptional") {
          toastMessage = "Category field marked as optional"
        } else if (flowName === "setFieldRequired") {
          toastMessage = "Category field marked as required"
        }
        toast.success(toastMessage)
      })
      .catch(() => {
        toast.error("Failed to disable category")
      })
  }

  const onDisableSingleCategory = (
    successCallback: () => void,
    action: "DISABLE" | "ENABLE",
    categoryId: string,
    isDefaultCategory = true
  ) => {
    enableDisableCategoryNetworkCall(
      action,
      isDefaultCategory ? "" : categoryId,
      isDefaultCategory ? categoryId : ""
    )
      .then(() => null)
      .catch(() => null)
      .finally(() => successCallback())
  }

  if (isLoading || fetchPaymentCategoriesLoading) {
    return (
      <Stack
        className="h-[320px]"
        width="full"
        justifyContent="center"
        alignItems="center"
        marginTop="24"
      >
        <SpinnerIcon size="12" />
      </Stack>
    )
  }

  return (
    <>
      <PageMeta>
        <title>Payment Category Settings</title>
      </PageMeta>
      <Box
        bgColor="white"
        paddingTop="6"
        paddingBottom="18"
        paddingX={{ xs: "4", md: "8" }}
        minHeight={{ xs: "screen", sm: "0" }}
      >
        <Box maxWidth="xl">
          <Stack gap="8">
            <Stack gap="6">
              <Box paddingX="6" paddingY="4" rounded="md" borderWidth="1">
                <Inline alignItems="start" gap="8">
                  <Stack gap="2" flex="1">
                    <Inline gap="2">
                      <Heading as="h3" fontSize="base" fontWeight="semibold">
                        Categories in Payments
                      </Heading>
                      <Tag />
                    </Inline>
                    <Box marginTop="1">
                      <Text fontSize="c2" color="textMedium">
                        Enable category field for payments
                      </Text>
                    </Box>
                  </Stack>
                  <Box
                    display="inlineBlock"
                    paddingX="3"
                    paddingY="1"
                    rounded="md"
                  >
                    <Switch
                      label={`Switch for ${fieldLabel} Field`}
                      on={!fieldDisabled}
                      onToggle={() => {
                        onConfirm(
                          fieldDisabled ? "enableField" : "disableField"
                        )
                      }}
                    />
                  </Box>
                </Inline>
              </Box>
              <Box paddingX="6" paddingY="4" rounded="md" borderWidth="1">
                <Inline alignItems="center" gap="8">
                  <Inline flex="1" alignItems="center">
                    <Stack gap="2" marginRight="2">
                      <Heading as="h3" fontSize="base" fontWeight="semibold">
                        Required field
                      </Heading>
                    </Stack>
                  </Inline>
                  <Box
                    display="inlineBlock"
                    paddingX="3"
                    paddingY="1"
                    rounded="md"
                  >
                    <Switch
                      label={`Switch for Required ${fieldLabel} Field`}
                      on={isCategoryRequiredField}
                      onToggle={() => {
                        if (!isCategoryFieldDisabled) {
                          onConfirm(
                            isCategoryRequiredField
                              ? "setFieldOptional"
                              : "setFieldRequired"
                          )
                        }
                      }}
                    />
                  </Box>
                </Inline>
              </Box>
            </Stack>
            <Stack gap="8">
              <Stack gap="4">
                <AddPaymentCategoryInDialog businessId={businessId}>
                  {({ add }) => (
                    <Button
                      size="lg"
                      fullWidth
                      onClick={() => add()}
                      disabled={fieldDisabled}
                    >
                      <PlusIcon />
                      Add New {fieldLabel}
                    </Button>
                  )}
                </AddPaymentCategoryInDialog>
              </Stack>
              <Stack gap="6">
                <SearchBar
                  value={search}
                  maxWidth="full"
                  label={`Search by name...`}
                  onChange={(value) => setSearch(value)}
                />
                {(search && !filteredDefaultCategories.length) ||
                filteredCustomCategories.length ? (
                  <Heading
                    as="h4"
                    fontSize="base"
                    fontWeight="semibold"
                    color="gray500"
                  >
                    {`Custom ${pluralize(
                      "Category",
                      filteredDefaultCategories
                    )}`}
                  </Heading>
                ) : null}
                <Stack as="ol" gap="4">
                  {filteredCustomCategories?.length ? (
                    filteredCustomCategories?.map((category) => {
                      const { name, enabled, id } = category
                      return (
                        <Inline
                          key={id}
                          as="li"
                          alignItems="center"
                          gap="4"
                          borderWidth="1"
                          rounded="md"
                          paddingX="4"
                          paddingY="2"
                        >
                          <Stack flex="1" gap="1">
                            <Text
                              className="break-all"
                              color={enabled ? "textHigh" : "textLow"}
                            >
                              {name}
                            </Text>
                          </Stack>
                          <Box>
                            <DeletePaymentCategoryInDialog
                              businessId={businessId}
                              categoryToBeDeleted={category}
                            >
                              {({
                                open: openDeleteCategoryConfirmationModal,
                              }) => (
                                <DisableCategoryConfirmationModal
                                  onConfirm={(onClose) => {
                                    onDisableSingleCategory(
                                      onClose,
                                      "DISABLE",
                                      category.id || "",
                                      false
                                    )
                                  }}
                                  title="Disable Category"
                                  loading={enableDisableCategoryLoading}
                                  pointers={[
                                    "This will not let anyone use this category in any new transaction",
                                    "Old transactions(if any) will remain tagged with this category",
                                  ]}
                                >
                                  {({ open: openDisableCategoryDialog }) => (
                                    <EditPaymentCategoryInDialog
                                      businessId={businessId}
                                      defaultValue={{
                                        id: category.id || "",
                                        name: category.name,
                                      }}
                                    >
                                      {({ open: openEditCategoryDialog }) => (
                                        <Menu>
                                          <MenuButton inline>
                                            <DotsVerticalIcon
                                              color={
                                                enabled
                                                  ? "iconMedium"
                                                  : "iconLow"
                                              }
                                            />
                                          </MenuButton>
                                          <MenuList align="bottom-right">
                                            <MenuItem
                                              action="rename"
                                              onClick={openEditCategoryDialog}
                                            >
                                              Edit
                                            </MenuItem>
                                            <MenuItem
                                              action="delete"
                                              onClick={() => {
                                                if (enabled) {
                                                  openDisableCategoryDialog()
                                                } else {
                                                  onDisableSingleCategory(
                                                    () => null,
                                                    "ENABLE",
                                                    category.id || "",
                                                    false
                                                  )
                                                }
                                              }}
                                            >
                                              {enabled ? "Disable" : "Enable"}
                                            </MenuItem>
                                            <MenuItem
                                              action="delete"
                                              onClick={
                                                openDeleteCategoryConfirmationModal
                                              }
                                              className="text-[#C93B3B]"
                                            >
                                              Delete
                                            </MenuItem>
                                          </MenuList>
                                        </Menu>
                                      )}
                                    </EditPaymentCategoryInDialog>
                                  )}
                                </DisableCategoryConfirmationModal>
                              )}
                            </DeletePaymentCategoryInDialog>
                          </Box>
                        </Inline>
                      )
                    })
                  ) : search && !filteredDefaultCategories.length ? (
                    <Stack gap="16" paddingTop="2">
                      <AddPaymentCategoryInDialog businessId={businessId}>
                        {({ add }) => (
                          <Inline
                            gap="4"
                            rounded="md"
                            paddingX="4"
                            paddingY="2"
                            alignItems="center"
                            height="12"
                            as="button"
                            onClick={() => add(search)}
                            backgroundColor="surfacePrimaryLowest"
                          >
                            <PlusIcon color="iconPrimary" />
                            <Text fontSize="b3">Add ‘{search}’</Text>
                          </Inline>
                        )}
                      </AddPaymentCategoryInDialog>
                      <Stack
                        gap="6"
                        textAlign="center"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Circle>
                          {fieldKey === "parties" ? (
                            <UsersFilledIcon />
                          ) : (
                            <LabelIcon />
                          )}
                        </Circle>
                        <Stack gap="2">
                          <Text fontSize="s3">
                            No {pluralize(`${fieldLabel}`).toLowerCase()} found!
                          </Text>
                          <Text fontSize="b3" color="textMedium">
                            Check spelling or try some other search term
                          </Text>
                        </Stack>
                      </Stack>
                    </Stack>
                  ) : null}
                </Stack>
                {filteredDefaultCategories.length ? (
                  <Heading
                    as="h4"
                    fontSize="base"
                    fontWeight="semibold"
                    color="gray500"
                  >
                    {`Default ${pluralize(
                      "Category",
                      filteredDefaultCategories
                    )}`}
                  </Heading>
                ) : null}
                <Stack as="ol" gap="4">
                  {filteredDefaultCategories?.length
                    ? filteredDefaultCategories?.map((category) => {
                        const { default_id, name, enabled } = category
                        return (
                          <Inline
                            key={default_id}
                            as="li"
                            alignItems="center"
                            gap="4"
                            borderWidth="1"
                            rounded="md"
                            paddingX="4"
                            paddingY="2"
                          >
                            <Stack flex="1" gap="1">
                              <Text
                                className="break-all"
                                color={enabled ? "textHigh" : "textLow"}
                              >
                                {name}
                              </Text>
                            </Stack>
                            <Box>
                              <DisableCategoryConfirmationModal
                                onConfirm={(onClose) => {
                                  onDisableSingleCategory(
                                    onClose,
                                    enabled ? "DISABLE" : "ENABLE",
                                    category.default_id || category.id || ""
                                  )
                                }}
                                title="Disable Category"
                                loading={enableDisableCategoryLoading}
                                pointers={[
                                  "This will not let anyone use this category in any new transaction",
                                  "Old transactions(if any) will remain tagged with this category",
                                ]}
                              >
                                {({ open }) => (
                                  <Menu>
                                    <MenuButton inline>
                                      <DotsVerticalIcon
                                        color={
                                          enabled ? "iconMedium" : "iconLow"
                                        }
                                      />
                                    </MenuButton>
                                    <MenuList align="bottom-right">
                                      <MenuItem
                                        action="disable"
                                        onClick={() => {
                                          if (enabled) {
                                            open()
                                          } else {
                                            onDisableSingleCategory(
                                              () => null,
                                              enabled ? "DISABLE" : "ENABLE",
                                              category.default_id ||
                                                category.id ||
                                                ""
                                            )
                                          }
                                        }}
                                      >
                                        {enabled ? "Disable" : "Enable"}
                                      </MenuItem>
                                    </MenuList>
                                  </Menu>
                                )}
                              </DisableCategoryConfirmationModal>
                            </Box>
                          </Inline>
                        )
                      })
                    : null}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </>
  )
}

function DisableCategoryConfirmationModal({
  onConfirm,
  children,
  title,
  loading,
  pointers,
}: {
  onConfirm: (successCallback: () => void) => void
  children: (props: { open: () => void }) => React.ReactNode
  title: string
  loading: boolean
  pointers: string[]
}) {
  const confirmation = useOverlayTriggerState({})

  return (
    <>
      {children({
        open: confirmation.open,
      })}
      <Modal
        isDismissable
        isOpen={confirmation.isOpen}
        onClose={confirmation.close}
        title={title}
      >
        <ModalBody>
          <Text>Are you sure?</Text>
          <Stack marginTop="4" gap="4">
            {pointers.map((pointer) => (
              <Inline key={pointer} alignItems="center" gap="4">
                <Circle size="2" backgroundColor="gray400" />
                <Text>{pointer}</Text>
              </Inline>
            ))}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            size="lg"
            disabled={loading}
            onClick={() => {
              onConfirm(confirmation.close)
            }}
          >
            {loading ? (
              <>
                <SpinnerIcon /> Yes
              </>
            ) : (
              <>Yes</>
            )}
          </Button>
          <Button size="lg" disabled={loading} onClick={confirmation.close}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
